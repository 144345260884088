<template>
    <section id="experience">
        <div style="min-height: 100vh" class="d-flex align-items-center">
            <div style="max-width: 850px" class="text-start">
                <h1 class="text-lightest-slate font-xl fw-bold"><span
                        class="text-info font-lg font-fira-code">02.</span> Where
                    I’ve Worked</h1>
                <div>
                    <h1 class="font-lg">
                        Full-Stack Developer, Bonyan Fan (2021 - Present)
                    </h1>
                    <p class="font-md">
                        As a Full-Stack Developer at Bonyan Fan, I am responsible for designing, developing, and
                        maintaining web applications using .Net core and Vue.js. My primary responsibilities include:
                    </p>
                    <ul class="font-md">
                        <li>
                            Collaborating with a team of developers to design and implement new features for the Bonyan
                            Fan website.
                        </li>
                        <li>
                            Developing and maintaining the front-end of the Bonyan Fan website using React, HTML, CSS,
                            and JavaScript.
                        </li>
                        <li>
                            Developing and maintaining the back-end of the Bonyan Fan website using
                            C#, .net core, Entity Framework, and Sql Server.
                        </li>
                        <li>
                            Participating in code reviews and ensuring code quality and consistency across the
                            application.
                        </li>
                        <li>
                            Investigating and resolving technical issues reported by users and the support team.
                        </li>     <li>
                        Contributing to the development of coding standards and best practices within the team.
                        </li>
                    </ul>
                </div>

            </div>
        </div>

    </section>
</template>

<script setup>

</script>

<style scoped>

</style>
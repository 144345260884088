<template>
    <section id="contact">
        <div style="min-height: 50vh" class="d-flex justify-content-center align-items-center">
            <div style="max-width: 800px" class="text-center">
                <h1 class="text-info font-fira-code">04. What’s Next?</h1>
                <h1 class="text-lightest-slate font-4xl fw-bolder">Get In Touch</h1>
                <p class="font-md">
                    I am currently on the lookout for new opportunities in the field of development, and I am always
                    open to new connections and collaborations. Feel free to drop me a line anytime - whether you have a
                    question, want to discuss a potential project, or just want to say hi. I will do my best to respond
                    as soon as possible. Looking forward to hearing from you!
                </p>
                <a href="mailto:h.shaker2001@gmail.com ">
                    <button class="btn btn-outline-info font-fira-code mt-4">Say Hello</button>
                </a>
            </div>
        </div>

    </section>
</template>

<script setup>

</script>

<style scoped>

</style>
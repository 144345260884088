<template>
    <ul class="gap-3 font-lg">
        <li><a href="#"> <i class="bi bi-instagram"/> </a></li>
        <li><a href="#"><i class="bi bi-twitter"></i></a></li>
        <li><a href="https://www.linkedin.com/in/hossein-shakeri-06235b258/"><i class="bi bi-linkedin"/></a></li>
        <li><a href="https://github.com/Hossein-cf/"> <i class="bi bi-github"/> </a></li>
        <li class="d-block d-md-none"><a href="mailto:h.shaker2001@gmail.com "> <i class="bi bi-envelope"/> </a></li>
    </ul>
</template>

<script>
export default {
    name: "social-media-icons"
}
</script>

<style scoped>

</style>